import type { AttributeTerm } from "../models/AttributeTerm"
import type { Attribute } from "../models/Attribute"
import { useI18n } from "vue-i18n"
import type { Product } from "../models/Product"
import type { Service } from "../models/Service"
import type { CoinWithExchangeRate } from "../models/CoinWithExchangeRate"
import type { Image as CImage } from "~/models/Image"
import moment from "moment"

/**
 * Indicate if user has the given permission.
 *
 * @param permission
 * @param props
 */

export function can(): boolean {
    // if (permission) {

    //     if (typeof permission == "function") {

    //         return permission(props)
    //     }

    //     if (typeof permission == 'string') {

    //         return props.auth.can.findIndex((p:any) => p == permission) != -1
    //     }

    //     for (const permissionElement of permission) {

    //         if (props.auth.can.findIndex((p:any) => p == permissionElement) != -1) {

    //             return true
    //         }
    //     }

    //     return false
    // }

    return true
}

/**
 * Find next id.
 *
 * @param collection
 */
export function findId<T extends { id: number }>(collection: T[]): number {
    let id = 0

    for (const item of collection) {
        if (item.id && item.id > id) {
            id = item.id
        }
    }

    return id + 1
}

/**
 * Get app name.
 *
 * @return {string}
 */
export function getAppName(): string {
    // @ts-ignore
    //return document.head.querySelector('meta[name="static_title"]').content ?? 'Laravel'
    return "MarketPlace en Nuxt"
}

/**
 * Route helper for the buttons.
 *
 * @param to
 */
export function goToRoute(to: any | null) {
    const router = useRouter()

    if (to) {
        try {
            router.push(to)
        } catch (e) {
            if (router.hasRoute(to)) {
                router.push(to)
            } else {
                window.location.href = to
            }
        }
    }
}

/**
 * Get attributes from terms with they terms inside.
 *
 * @param allAttributes
 * @param terms
 * @param withPlaceholder
 * @return Attribute[]
 */
export function getAttributesFromTerms(
    allAttributes: Attribute[],
    terms: AttributeTerm[],
    withPlaceholder?: boolean
): Attribute[] {
    const list: Attribute[] = []

    const hasOneAttribute = (): boolean => {
        if (terms.length > 0) {
            const id = terms[0].attribute_id

            for (const term of terms) {
                if (term.attribute_id != id) {
                    return false
                }
            }
        }

        return true
    }

    for (const term of terms) {
        const findPredicate = (a: any) => a.id == term.attribute_id
        let attribute = list.find(findPredicate)

        if (!attribute) {
            attribute = allAttributes.find(findPredicate)
            attribute!.terms = []

            if (withPlaceholder == true && !hasOneAttribute()) {
                const i18n = useI18n()
                attribute!.terms.push({
                    id: -1,
                    value: i18n.t("attributes.placeholder", {
                        attribute: attribute!.name,
                    }),
                    attribute_id: attribute!.id,
                })
            }
            if (attribute !== undefined) {
                list.push(attribute)
            }
        }

        attribute!.terms.push(term)
    }

    return list
}

/**
 * Get the combination count of a number of terms.
 *
 * @param termsLength
 */
export function getTermsCombinationsCount(termsLength: number): number {
    let combinations = Math.pow(termsLength, 2)

    if (combinations > 1) {
        combinations -= termsLength
    }

    return combinations
}

/**
 * Get setting value for the given key.
 *
 * @param key
 */
export function settings(key: string): string | boolean | undefined {
    return true
}

/**
 * Get categories names.
 */
export function getCategoriesNames(salable: Product | Service): string {
    let text = ""
    if (salable.categories !== null && salable.categories !== undefined) {
        for (let i = 0; i < salable.categories.length; i++) {
            const category = salable.categories[i]
            if (typeof category !== "number") {
                text += category.name
            }

            if (i < salable.categories.length - 1) {
                text += ", "
            }
        }
    }

    return text
}

export function price_formated(
    price: number,
    coins: string,
    exchange_rate: Array<CoinWithExchangeRate>
): string {
    let priceFormat: string = "vacio"
    let exchange: number = 1
    exchange_rate.forEach((element) => {
        if (element.coin.short_name == coins) {
            exchange = element.exchange
            if (element.coin.position == "front") {
                priceFormat =
                    element.coin.symbol +
                    numberFormatMilis(price * element.exchange)
            } else {
                priceFormat =
                    numberFormatMilis(price * element.exchange) +
                    element.coin.symbol
            }
            return priceFormat
        }
    })

    return priceFormat
}

export function numberFormatMilis(number: number) {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g
    const rep = "$1,"
    let arr = number.toFixed(2).split(".")
    arr[0] = arr[0].replace(exp, rep)
    return arr[1] ? arr.join(".") : arr[0]
}

export function productWithAttributes(product: Product) {
    return product.attribute_terms!.length > 0
}

export function regexPhone(value: string): boolean | string {
    if (
        !/^(\+?\(?\d+\)?[\d\s-]{5,}|)$/.test(value) &&
        value != "" &&
        value != null
    ) {
        return "El número de teléfono no cumple con su formato"
    }
    return true
}

export function regexPhoneCuban(value: string | null): boolean | string {
    if (value !== null) {
        if (!/^\d{8}$/.test(value) && value != "" && value != null) {
            return "El número de teléfono no cumple con el formato de Cuba"
        }
        return true
    } else return false
}

export function regexCI(value: string): boolean | string {
    value = value.replace(/\s/g, "")
    if (!/^[0-9]{11}$/.test(value.trim()) && value != "" && value != null) {
        return "El número de CI no cumple con el formato"
    }
    return true
}
export const tailwindLikeBreakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
}

export const buttonPositions = {
    TOP_CENTER: "top-center",
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    CENTER_LEFT: "center-left",
    CENTER_RIGHT: "center-right",
    CENTER: "center",
    BOTTOM_CENTER: "bottom-center",
    BOTTOM_RIGHT: "bottom-right",
    BOTTOM_LEFT: "bottom-left",
}

export const IMG_PLACEHOLDER = "/img/product_placeholder.png"

export const timeClearSpinner = 30000

/**
 * Function to set the source of an image to a placeholder image when an error occurs.
 *
 * @param {Event} event - The event object that triggered the image error.
 * @return {void} This function does not return anything.
 */
export function setAltImg(event: any) {
    // Set the source of the image to the placeholder image
    event.target.src = IMG_PLACEHOLDER
}
export function arrayItemsError(fieldName: string, errorsBag: String[]) {
    const ownProperties = Object.getOwnPropertyNames(errorsBag)
    const properties = ownProperties.filter((i: string) => {
        return i.startsWith(fieldName)
    })

    const o: { [key: string]: { [key: string]: any } } = {}

    properties.forEach((element: string) => {
        const splitted = element.split(".")
        if (splitted.length >= 3) {
            const index = splitted[1]
            o[index] = o[index] ?? {}
            const f = splitted[2]
            const tmp = errorsBag.filter((f) => f === element)
            const element1 = tmp[0]
            o[index][f] = element1
        }
    })

    return o
}

/**
 * Renders the image based on the given input. If the input is a string, it returns the string.
 * If the input has a "thumb" property, it returns the value of the "thumb" property. If the input is a File object,
 * it creates a URL for the file. If the input is undefined or not one of the specified types, it returns a placeholder image URL.
 *
 * @param {Image|File|undefined|string} image - The image to be rendered.
 * @return {string} The rendered image URL or placeholder image URL.
 */
export function renderImage(
    image: CImage | File | HTMLImageElement | undefined | string | Blob
) {
    if (image) {
        if (image instanceof HTMLImageElement) {
            return image.src
        } else if (typeof image == "string") {
            return image
        } else if ("thumb" in image) {
            return <string>image.thumb
        } else {
            return URL.createObjectURL(image)
        }
    }

    return IMG_PLACEHOLDER
}

export function downloadImage(
    image: CImage | File | HTMLImageElement | undefined | string | Blob,
    filename: string = "new_image"
) {
    const url = renderImage(image)

    const a = document.createElement("a")
    a.href = url
    a.download = filename
    a.click()
}

export async function loadImageResolveImg(
    path: string
): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = path
        img.onload = () => {
            resolve(img)
        }
    })
}

export async function downscalerImage(dataUrl: string, filename: string) {
    return new Promise((resolve) => {
        const image = document.createElement("img")
        image.addEventListener("load", async () => {
            const canvas = document.createElement("canvas")
            const width = image.width
            const height = image.height
            image.width = width
            image.height = height
            canvas.width = width
            canvas.height = height

            image.style.transform = "scale(2)"
            image.style.imageRendering = "high-quality"
            canvas.style.transform = "scale(2)"
            canvas
                .getContext("2d")
                ?.drawImage(image, 0, 0, image.width, image.height)
            let file: File
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        file = new File([blob], filename ?? dataUrl, {
                            type: blob.type,
                        })

                        resolve(file)
                    }
                },
                "image/jpeg",
                0.65
            )
        })

        image.src = dataUrl
    })
}

export function calculatetime(date: any) {
    const fechaActual = moment() // Obtener la fecha y hora actual
    const fechaPasada = moment(date) // Convertir la fecha pasada a un objeto moment

    const diasPasados = fechaActual.diff(fechaPasada, "days") // Calcular los días transcurridos
    const horasPasadas = fechaActual.diff(fechaPasada, "hours") // Calcular las horas transcurridas
    const minutosPasados = fechaActual.diff(fechaPasada, "minutes") // Calcular los minutos transcurridos

    return {
        dias: diasPasados,
        diasFormat: `${diasPasados} días`,
        horas: horasPasadas,
        horasFormat: `${horasPasadas} horas`,
        minutos: minutosPasados,
        minutosFormat: `${minutosPasados} minutos`,
    }
}

export function upscalerImage(
    dataUrl: string,
    filename: string,
    afterUpscaler: (img: File) => void
) {
    const image = document.createElement("img")
    image.addEventListener("load", async () => {
        const canvas = document.createElement("canvas")
        const width = image.width * 2
        const height = image.height * 2
        image.width = width
        image.height = height
        canvas.width = width
        canvas.height = height

        image.style.transform = "scale(2)"
        image.style.imageRendering = "high-quality"
        canvas.style.transform = "scale(2)"
        canvas
            .getContext("2d")
            ?.drawImage(image, 0, 0, image.width, image.height)
        const dataUrl = canvas.toDataURL()
        const blob = await (await fetch(dataUrl)).blob()
        const file: File = new File([blob], filename, { type: blob.type })

        afterUpscaler(file)
    })

    image.src = dataUrl
}

export function sanitazeHtml(s: string) {
    return s
        .replace(/(<([^>]+)>)/gi, "")
        .replace("•", "")
        .replace("&nbsp;", "\n")
        .replace("&nbsp", "\n")
}

function checkIfImageExists(url: string, callback: (exists: boolean) => void) {
    const img = new Image()
    img.src = url

    if (img.complete) {
        callback(true)
    } else {
        img.onload = () => {
            callback(true)
        }

        img.onerror = () => {
            callback(false)
        }
    }
}

export const limitSizeImage = 307200

export function today() {
    const initSettingsStore = useDefaultSettingsStore()

    return initSettingsStore.settings.today
}

export function capitalizeFirstLetter(word: string) {
    if (!word || typeof word !== "string") return word
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export const generatePerPageOptions = (
    page: number,
    options: { title: string; value: number }[] = []
): { title: string; value: number }[] => {
    if (!page || page < 0) return []

    let value = page
    let decrement = 10

    if (page <= 5) {
        options.push({ title: page.toString(), value: page })
        return options
    }

    if (page % 5 !== 0) {
        let rest = page % 5
        value = page - rest
    }
    if (value > 5 && value <= 10) {
        decrement = 5
    }

    if (options.every((option) => option.value !== value)) {
        options.push({ title: value.toString(), value: value })
    }

    return generatePerPageOptions(value - decrement, options)
}

export const formattedPerPageOptions = (
    page: number,
    options: { title: string; value: number }[] = [],
    max: number = 50
): { title: string; value: number }[] => {
    const formattedOptions = generatePerPageOptions(
        page < max ? page : max,
        options
    )
    let biggestOption = formattedOptions[0]
    formattedOptions.forEach((option) => {
        if (option.value >= biggestOption.value) {
            biggestOption = option
        }
    })

    formattedOptions.sort((a, b) => b.value - a.value)

    return formattedOptions
        .map((option) => {
            if (
                option.title === biggestOption.title &&
                option.value === biggestOption.value
            ) {
                return {
                    title: "Todas",
                    value: page,
                }
            }

            return option
        })
        .reverse()
}

function replaceMonthNameToSpanish(dateString: any) {
    const months = {
        January: "Enero",
        February: "Febrero",
        March: "Marzo",
        April: "Abril",
        May: "Mayo",
        June: "Junio",
        July: "Julio",
        August: "Agosto",
        September: "Septiembre",
        October: "Octubre",
        November: "Noviembre",
        December: "Diciembre",
    }

    let parts = dateString.split(" ")
    parts[1] = months[parts[1]]

    return parts.join(" ")
}

export function formatDate(dateString: any) {
    let localLocale = moment(dateString)
    if (typeof dateString !== "string" || dateString == "") {
        localLocale = moment()
    } else {
        localLocale = moment(dateString)
    }

    localLocale.locale("es")
    return replaceMonthNameToSpanish(localLocale.format("DD MMMM YYYY"))
}

export const firstDayOfMonthFormatted = () => {
    const today = new Date()
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2)
    return firstDayOfMonth.toISOString().split("T")[0]
}

export const formatTime = (timeString: string) => {
    let [hours, minutes] = timeString.split(":").map(Number)
    const period = hours >= 12 ? "PM" : "AM"
    hours = hours % 12 || 12

    return `${hours}:${minutes.toString().padStart(2, "0")}${period}`
}
